<template>
  <div class="article">
    <section class="section">
      <div class="left">
        <ArticleTab></ArticleTab>
      </div>
      <div class="right">
        <Sidebar></Sidebar>
      </div>
    </section>
  </div>
</template>
<script>
  import ArticleTab from '@/components/home/article'
  import Sidebar from '@/components/sidebar'

  export default {
    name: '',
    components: {
      ArticleTab,
      Sidebar
    },
    data() {
      return {
        heigh: '900px'
      }
    },
    methods: {},
    created() {
    }
  }
</script>
<style lang="less" scoped>
  .article {
    width: 100%;
    overflow: hidden;
    .section {
      min-width: 1200px;
      margin: 30px auto;
      overflow: hidden;
      .left {
        float: left;
        width: 70%;
      }
    }
  }
</style>